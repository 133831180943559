@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;700&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  background-color: rgb(33, 0, 92);
}
.hiddenScrollBar::-webkit-scrollbar {
  display: none;
}
